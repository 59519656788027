
import api from '@/api/co.api'
import app from '@/api/co.app'
import act from '@/api/co.action'
import notice from '@/api/co.notice'

import noticePreset from './mix.notice.preset'

export default {
  data () {
    return {
      notParams: null,
      notItems: [],
      notPagination: {
        current: 1,
        length: 1,
        totalVisible: 7
      },
      notLoading: false,
      notSearch: {
        type: '',
        value: ''
      },
      sideParts: [],
      navPart: {}
    }
  },
  methods: {
    onNotice (ev) {
      const item = ev.item
      this.toNotice(this.navPart, item)
    },
    onSideNotice (part, item) {
      this.toNotice(part, item)
    },
    toNotice (part, item) {
      act.navigate({
        that: this,
        pagename: api.page.noticeDetail.name,
        item,
        category: part
      })
    },
    onSearchKeyDown (ke) {
      if (ke.key === 'Enter') {
        this.onSearch()
      }
    },
    clearSearch () {
      this.toSearch({})
    },
    onSearch () {
      const search = this.notSearch.value || ''
      let searchType = ''
      if (search) {
        searchType = 'title'
      }
      this.toSearch({ search, searchType })
    },
    toSearch ({
      search = '',
      searchType = ''
    }) {
      const params = this.notParams
      const data = params.data

      params.reload = true
      data.offset = 0
      data.search = search
      data.searchType = searchType
      api.http.getItems(params)
    },
    changePagination (number) {
      const params = this.notParams
      const data = params.data
      const limit = parseInt(data.limit, 10) || 9
      const index = parseInt(number, 10) || 1

      this.notPagination.current = index
      data.offset = (index - 1) * limit
      params.reload = true
      api.http.getItems(params)
    },
    initSideParams (obj) {
      const executed = function (res) {
        if (res.status) {
        }
      }

      obj.params = notice.getParams({
        siteId: app.init.siteId,
        type: obj.type,
        topicId: obj.topicId,
        categoryId: obj.categoryId,
        status: 'publish',
        limit: '3',
        caches: obj.items,
        executed
      })
    },
    loadSideNotices (reload = true) {
      for (const key in this.sideParts) {
        const item = this.sideParts[key]
        item.params.reload = reload
        api.http.getItems(item.params)
      }
    },
    configSide (exclude = []) {
      this.sideParts = []
      const parts = noticePreset.Parts
      for (const key in parts) {
        const part = parts[key]
        if (exclude.indexOf(part.name) === -1) {
          const dst = api.comm.deepClone(part)
          this.initSideParams(dst)
          this.sideParts.push(dst)
        }
      }
      this.loadSideNotices(true)
    },
    initNoticeParams ({
      name = '',
      type = 'notice_news',
      topicId = '',
      categoryId = '',
      limit = '10'
    }) {
      const me = this
      const executing = function () {
        me.notLoading = true
      }

      const executed = function (res) {
        me.notLoading = false
        if (res.status) {
          const data = me.notParams.data
          const pagination = me.notPagination
          const limit = parseInt(data.limit) || 10
          pagination.length = Math.ceil(res.total / limit)
        }
      }

      this.configSide([noticePreset.Names.NAVIGATE, name])

      this.notParams = notice.getParams({
        siteId: app.init.siteId,
        type,
        topicId,
        categoryId,
        limit,
        caches: this.notItems,
        executing,
        executed
      })
    },
    loadNotices (reload = true) {
      this.notParams.reload = reload
      api.http.getItems(this.notParams)
    }
  }
}
